import { Button, Form, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import PageLayout from '../SignUp/SignUpLayout'
import { httpPost } from '../../services/http'
import { MailOutlined } from '@ant-design/icons'

const ResetPassword = () => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const error = (message: string) => {
        Modal.error({
            title: 'Request failed',
            content: message,
        })
    }

    const onFinish = async (values: any) => {
        setLoading(true)
        try {
            await httpPost<Response>('/auth/forgot-password', values)
            message.success('Sent an email with a reset-link')
            Modal.success({
                title: 'E-mail Sent!',
                content: 'Please go to your email inbox and click the link in the mail',
                onOk: () => {
                    navigate('/login/')
                },
                onCancel: () => {
                    navigate('/login/')
                },
            })
        } catch (err) {
            if (err.status === 400) {
                error(err.data?.detail)
            } else {
                error(err.message)
            }
            setLoading(false)
            form.resetFields()
        }
    }

    return (
        <Form onFinish={onFinish}>
            <Form.Item>
                <p>
                    Enter the email address associated with your account and we&apos;ll send you a
                    link to reset your password.
                </p>
            </Form.Item>
            <Form.Item
                hasFeedback
                name="email"
                rules={[
                    { required: true, message: 'Please enter your email!' },
                    { type: 'email', message: 'Not a valid email' },
                ]}
                className="signin-input"
                label="E-mail"
            >
                <Input
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                    className="signin-input"
                />
            </Form.Item>
            <Form.Item>
                <Button
                    className="landing-button"
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    loading={loading}
                >
                    Reset Password
                </Button>
            </Form.Item>
            <Form.Item>
                <div style={{ flexDirection: 'row' }}>
                    <p>Remembered?</p>
                    <Link to="/login/" replace>
                        <Button
                            className="landing-button"
                            type="primary"
                            shape="round"
                            size="small"
                        >
                            Go back to login
                        </Button>
                    </Link>
                </div>
            </Form.Item>
        </Form>
    )
}

const ResetPasswordContainer = () => {
    return (
        <PageLayout title="Forgot Password">
            <div className="login-background">
                <div className="login-background-inner">
                    <div className="login-container">
                        <div>
                            <Link to="/" replace>
                                <div className="logoImgLarge" />
                            </Link>
                        </div>
                        <ResetPassword />
                    </div>
                    <br />
                </div>
            </div>
        </PageLayout>
    )
}

export default ResetPasswordContainer
